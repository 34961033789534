import React from "react";
import TextField from "@mui/material/TextField";

const CustomTextField = ({
  id,
  label,
  variant,
  name,
  formik,
  multiline,
  rows,
}) => {
  // Show error if the form has been submitted at least once and there's an error for this field
  const showError = formik.submitCount > 0 && Boolean(formik.errors[name]);

  return (
    <TextField
      fullWidth
      id={id}
      label={label}
      value={formik?.values?.[name]}
      variant={variant || "outlined"}
      onChange={(e) => formik.setFieldValue(name, e.target.value)}
      error={showError}
      helperText={showError ? formik.errors[name] : ""}
      multiline={multiline}
      rows={multiline ? rows : 1}
    />
  );
};

export default CustomTextField;
