import React from "react";
import { Box, Typography, Button, Paper, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
function PricingCard({ title, price, features, recommended }) {
  let navigate = useNavigate();

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Paper
        elevation={0}
        sx={{
          position: "relative",
          p: { xs: 4, sm: 6 },
          mb: 4,
          borderRadius: "xl",
        }}
      >
        {recommended && (
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              top: 16,
              right: "-10%",
              top: "10%",
              transform: "rotate(-270deg)",
              bgcolor: "primary.main",
              px: 2,
              py: 1,
              borderRadius: "0 0 4px 4px",
              color: "white",
            }}
          >
            Recommended
          </Typography>
        )}
        <Typography variant="h5" sx={{ mb: 3, color: "text.primary" }}>
          {title}
        </Typography>
        <Typography variant="h3" sx={{ mb: 2, color: "text.primary" }}>
          <span>$</span>
          {price}
        </Typography>
        <Typography sx={{ mb: 5, color: "text.secondary" }}>Per One</Typography>
        <Box sx={{ mb: 3 }}>
          {features.map((feature, index) => (
            <Typography key={index} sx={{ color: "text.secondary" }}>
              {feature}
            </Typography>
          ))}
        </Box>
        <Button
          onClick={() => navigate("/wizard")}
          variant="contained"
          sx={{
            mt: 3,
            bgcolor: "primary.main",
            "&:hover": { bgcolor: "primary.dark" },
          }}
        >
          Explore More !
        </Button>
      </Paper>
    </Grid>
  );
}

export default PricingCard;
