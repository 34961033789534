import React, { useContext, useState } from "react";
import CustomTextField from "../../components/common/CustomTextField/CustomTextField";
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { UserContext } from "../../context/Usercontext";
import CustomCheckbox from "../../components/common/CustomCheckBox/CustomCheckbox";
import { ReactComponent as Link } from "../../assets/linkedin.svg";
import { color3 } from "../../theme/theme";

export default function FillDetailsLinkedIn({ formik }) {
  return (
    <Grid style={{ paddingTop: "60px" }} container>
      <Grid xs={12}>
        <Typography variant="h6" color={color3} textAlign="center">
          🌟 Unveil the Power of Your LinkedIn Profile! 🌟 Upload your CV and
          let's embark on a journey to elevate your professional identity.
          Together, we'll sculpt, polish, and magnify your career narrative.
          Your next career milestone is just a profile review away! 🚀 Your
          dream opportunity is calling! 📈
        </Typography>
      </Grid>
      <Grid
        style={{ paddingTop: "20px" }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        md={7}
        xs={12}
      >
        <Typography variant="h5" gutterBottom>
          Please enter the URL of your LinkedIn profile
        </Typography>
        <div style={{ marginBottom: "30px" }}>
          <CustomTextField
            label="LinkedIn URL"
            name="linkedinUrl"
            formik={formik}
          />
        </div>
        <div style={{ marginBottom: "30px" }}>
          <CustomTextField label="Email" name="email" formik={formik} />
        </div>
        <CustomCheckbox
          label="I agree to provide my LinkedIn profile information"
          name="agreed" // The name of the field in your Formik state
          formik={formik}
        />
      </Grid>
      <Grid
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        md={5}
        xs={12}
      >
        <Link style={{ maxHeight: "500px" }} />
      </Grid>
    </Grid>
  );
}
