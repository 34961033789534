import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import FAQItem from "./FAQItem";
import DescriptionIcon from "@mui/icons-material/Description";
import SpeedIcon from "@mui/icons-material/Speed";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

function FAQSection() {
  const faqs = [
    {
      question: "How Comprehensive is the Linkedin Review Feature?",
      answer:
        "Our resume review tool offers a detailed analysis of your resume, providing personalized feedback and suggestions for improvement within less than 30 Min.",
      icon: <DescriptionIcon sx={{ fontSize: 32, color: "white" }} />,
    },
    {
      question: "What Templates are Available for Resumes and Cover Letters?",
      answer:
        "We offer a Very good customizable templates for both resumes and cover letters, catering to various job industries and career levels.",
      icon: <SpeedIcon sx={{ fontSize: 32, color: "white" }} />,
    },
    {
      question: "Is there a trial period for the services?",
      answer:
        "Currently, we don't offer a trial period, but our services are priced affordably to ensure they are accessible to everyone.",
      icon: <FactCheckIcon sx={{ fontSize: 32, color: "white" }} />,
    },
    {
      question: "Is There Support Available for Users?",
      answer:
        "Absolutely! Our dedicated support team is available to assist you with any queries or issues you may have while using the app.",
      icon: <SupportAgentIcon sx={{ fontSize: 32, color: "white" }} />,
    },
    // Add more FAQs as needed
  ];

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        py: { xs: 8, lg: 15 },
        px: { xs: 6, lg: 5 },
        overflow: "hidden",
      }}
    >
      <Box sx={{ maxWidth: 520, mx: "auto", textAlign: "center", mb: 7 }}>
        <Typography variant="subtitle1" color="primary.main" gutterBottom>
          FAQ
        </Typography>
        <Typography variant="h2" gutterBottom>
          Any Questions? Look Here
        </Typography>
        <Typography sx={{ mx: "auto", maxWidth: 485 }}>
          Crafted to meet the needs of both emerging job hunters and experienced
          professionals.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {faqs.map((faq, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <FAQItem
              icon={faq.icon}
              question={faq.question}
              answer={faq.answer}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default FAQSection;
