import React from "react";
import {
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  Box,
} from "@mui/material";

const CustomCheckbox = ({ label, name, formik }) => {
  return (
    <Box>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                formik?.setFieldValue(name, e.target.checked);
              }}
              name={name}
              checked={formik?.values?.[name]}
            />
          }
          label={label}
        />
        <FormHelperText style={{ color: "red" }}>
          {formik?.errors?.[name]}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default CustomCheckbox;
