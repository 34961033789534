import React, { useContext } from "react";
import {
  Typography,
  Grid,
  Container,
  Box,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import { UserContext } from "../../context/Usercontext";
import { ReactComponent as Review } from "../../assets/review.svg";

const ReviewDetailsLinkedIn = () => {
  const { userData } = useContext(UserContext);

  // Assuming the server response is stored in userData
  const profileData = userData?.linkedInProfileData || {};

  return (
    <Container style={{ position: "relative" }}>
      <Box style={{ padding: "20px", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h2" gutterBottom>
              Review Your Details
            </Typography>
            <Avatar
              alt={profileData?.full_name}
              src={profileData?.profile_pic_url}
              sx={{ margin: "20px", width: 100, height: 100 }}
            />
            <Typography variant="h6">{profileData?.full_name}</Typography>
            <Typography variant="h6">{userData?.email}</Typography>
            <Typography variant="body1">{profileData?.summary}</Typography>
            <Typography variant="subtitle1">
              Location: {profileData?.city}, {profileData?.country_full_name}
            </Typography>
            <Typography variant="subtitle1">
              Languages: {profileData?.languages?.join(", ")}
            </Typography>

            <Typography variant="h6" gutterBottom>
              Education
            </Typography>
            <List>
              {profileData?.education?.map((edu, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={edu?.school}
                    secondary={edu?.field_of_study + ", " + edu?.degree_name}
                  />
                </ListItem>
              ))}
            </List>

            <Typography variant="h6" gutterBottom>
              Experience
            </Typography>
            <List>
              {profileData?.experiences?.map((exp, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={exp?.company}
                    secondary={
                      new Date(
                        exp?.starts_at?.year,
                        exp?.starts_at?.month - 1
                      ).toLocaleDateString() +
                      " - " +
                      (exp?.ends_at
                        ? new Date(
                            exp?.ends_at?.year,
                            exp?.ends_at?.month - 1
                          ).toLocaleDateString()
                        : "Present")
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid
            style={{ display: "flex" }}
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            md={4}
          >
            <Review style={{ minHeight: "250px" }} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ReviewDetailsLinkedIn;
