import React from "react";
import { Container, Box, Typography, Button, Grid } from "@mui/material";
import { Download, Star, Work } from "@mui/icons-material"; // Placeholder icons for demonstration
import HeroImage from "../../../assets/hero-image.webp";
import { ReactComponent as Decoration } from "../../../assets/decorationForSection.svg";
import { useNavigate } from "react-router-dom";

function HomePage() {
  let navigate = useNavigate();
  return (
    <Box
      id="home"
      sx={{
        overflow: "hidden",
        bgcolor: "primary.main",
        pt: { xs: "100px", md: "110px", lg: "130px" },
        textAlign: "center",
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ maxWidth: 780, mx: "auto" }}>
              <Typography
                variant="h3"
                sx={{
                  mb: 4,
                  fontSize: { sm: "3xl", md: "4xl", lg: "5xl" },
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Enhance Your Resume and Cover Letter with "Career UPHOLD"
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mx: "auto",
                  mb: 3,
                  maxWidth: 600,
                  fontSize: { sm: "lg" },
                  color: "white",
                }}
              >
                Elevate your career prospects with our comprehensive resume and
                cover letter review tool. Tailored for job seekers and
                professionals.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                mb: 5,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Button
                onClick={() => navigate("/wizard")}
                variant="contained"
                startIcon={<Work />}
                sx={{
                  bgcolor: "white",
                  color: "text.primary",
                  "&:hover": { bgcolor: "gray.200" },
                }}
              >
                Lets Start
              </Button>
              <Button
                href="#"
                target="_blank"
                variant="contained"
                startIcon={<Star />}
                sx={{
                  bgcolor: "white",
                  color: "text.primary",
                  opacity: 0.7,
                  "&:hover": { bgcolor: "gray.200" },
                }}
              >
                Review Our App
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: 1, color: "white" }}>
              We help you to find your Dream job
            </Typography>
          </Grid>

          <Grid item xs={12} position="relative">
            <Box sx={{ position: "relative", maxWidth: 845, mx: "auto" }}>
              <img
                src={HeroImage} // Replace with your image path
                alt="hero"
                style={{
                  zIndex: 2,
                  maxWidth: "100%",
                  borderRadius: "8px 8px 0 0",
                }}
              />
              {/* Placeholder for decorative SVG - Replace with your SVG */}
              <Box
                sx={{
                  position: "absolute",
                  left: "-36px",
                  bottom: 0,
                  zIndex: 1,
                }}
              >
                <Box component="span" sx={{ color: "white" }}>
                  <Decoration />
                </Box>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  right: "-24px",
                  top: "-24px",
                }}
              >
                <Box component="span" sx={{ color: "white" }}>
                  <Decoration />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HomePage;
