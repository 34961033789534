import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Switch,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import BlueLogo from "../assets/Logo/blue-logo.svg";
import WhiteLogo from "../assets/Logo/white-logo.svg";
import { useNavigate } from "react-router-dom";
import { color1 } from "../theme/theme";

export default function Navbar() {
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  let navigate = useNavigate();

  const handleScroll = () => {
    const offset = window.scrollY;
    console.log("here");
    setIsSticky(offset > 50);
    // Add further scroll related logic here
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
    // Implement theme switching logic here, like updating a global state or context
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };
  const fontColor = isSticky ? color1 : "rgba(255, 255, 255, 1)";

  return (
    <AppBar
      elevation={0}
      position="sticky"
      sx={{
        backgroundColor: isSticky ? "rgba(255, 255, 255, 0.9)" : "primary",
        color: fontColor,
      }}
    >
      <Toolbar>
        {/* <IconButton
          edge="start"
          color={fontColor}
          aria-label="menu"
          onClick={handleMobileMenuOpen}
        >
          <MenuIcon />
        </IconButton> */}
        {/* <Menu
          anchorEl={mobileMenuAnchorEl}
          open={Boolean(mobileMenuAnchorEl)}
          onClose={handleMobileMenuClose}
        >
       
          <MenuItem onClick={handleMobileMenuClose}>Home</MenuItem>
       
        </Menu> */}
        <Typography
          onClick={() => navigate("/")}
          variant="h6"
          style={{ flexGrow: 1 }}
        >
          {isSticky ? (
            <img src={BlueLogo} width="150px" />
          ) : (
            <img src={WhiteLogo} width="150px" />
          )}
        </Typography>
        {/* Other navigation items */}
        {console.log(fontColor, "fontColor")}
        <Button
          onClick={() => navigate("/wizard")}
          variant="outlined"
          sx={{ color: fontColor, border: `1px solid ${fontColor}` }}
        >
          Lets Start !
        </Button>
      </Toolbar>
    </AppBar>
  );
}
