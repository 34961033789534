import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { UserContext } from "../../context/Usercontext";
import { services as servicesConstants } from "../../helpers/Constants";

const StyledPaper = styled(Box)({
  padding: "30px",
  margin: "20px",
  textAlign: "center",
  //   boxShadow: "0 4px 15px rgba(0,0,0,0.2)",
});

const Highlight = styled("span")({
  color: "#635bff", // Adjust this color as needed
  fontWeight: "bold",
});
const PreviewBox = styled(Box)({
  position: "relative",
  marginBottom: "30px",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "70%",
    background: "linear-gradient(to top, rgba(255,255,255,0.8), transparent)",
  },
});

const PreviewResultCover = () => {
  const { userData, services } = useContext(UserContext);
  const [preview, setPreview] = useState(null);

  const selected = services.find((service) => service.name === userData?.type);
  const onPaymentClick = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/payment/`, {
        orderId: userData?.orderId,
      })
      .then((response) => {
        window.location.href = response.data?.data?.url;
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Payment initiation failed");
      });
  };

  const getResumeReviewBasic = async () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/services/resume/review`, {
        resumeId: userData?.resumeId,
      })
      .then((response) => {
        if (response?.data?.data) {
          setPreview(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Server Error");
      });
  };
  const getCoverLetterPreview = async () => {
    console.log("hereeeee");
    axios
      .post(`${process.env.REACT_APP_API_URL}/services/cover-letter/preview`, {
        coverLetterId: userData?.coverLetterId,
      })
      .then((response) => {
        if (response?.data?.data) {
          setPreview(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Server Error");
      });
  };

  // eslint-disable-next-line

  useEffect(() => {
    console.log(userData?.type, "userData?.type");
    switch (userData?.type) {
      case servicesConstants.coverLetter:
        getCoverLetterPreview();
        break;
      case servicesConstants.resumeLevelUp:
        getResumeReviewBasic();
      default:
        break;
    }
  }, []);

  return (
    <StyledPaper elevation={3}>
      <Typography
        variant="h4"
        gutterBottom
        style={{ color: "#333", fontWeight: "bold" }}
      >
        Unlock Your Career Potential!
      </Typography>
      <StarIcon style={{ color: "gold", fontSize: "40px" }} />
      {preview && (
        <PreviewBox>
          <Typography
            style={{ textAlign: "left", whiteSpace: "pre-line" }}
            variant="body1"
            component="p"
          >
            {preview}
          </Typography>
        </PreviewBox>
      )}

      <Typography variant="h6" component="p" style={{ margin: "20px 0" }}>
        This is just a glimpse of what we offer.{" "}
        <Highlight>Invest in your future</Highlight> and access the full,
        professionally tailored {selected?.displayName}. It's a small investment
        in your future and journey to success.
      </Typography>
      <Box m={2}>
        <Button variant="contained" color="secondary" onClick={onPaymentClick}>
          {` Pay with Stripe  ${
            selected ? (selected?.price / 100).toFixed(2) : ""
          } $ Only !`}
        </Button>
      </Box>
    </StyledPaper>
  );
};

export default PreviewResultCover;
