import React from "react";
import { Box, Typography, Grid, Link } from "@mui/material";

function FeatureItem({ icon, delay, title, description }) {
  return (
    <Grid item xs={12} md={6} lg={3}>
      <Box className="wow fadeInUp" data-wow-delay={delay} sx={{ mb: 12 }}>
        {/* Icon container */}
        <Box
          sx={{
            position: "relative",
            mb: 3,
            height: 70,
            width: 70,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 2,
            bgcolor: "primary.main",

            "&:hover span": {
              transition: "0.5s",
              transform: "rotate(45deg)",
            },
          }}
        >
          {/* Placeholder for icon */}
          <Box
            component="span"
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              height: 70,
              width: 70,
              transform: "rotate(25deg)",
              bgcolor: "primary.main",
              opacity: 0.2,
            }}
          ></Box>
          {/* Replace with actual SVG icon */}
          <Typography sx={{ color: "white" }}>{icon}</Typography>
        </Box>

        <Typography
          sx={{
            mb: 3,
            fontSize: "xl",
            fontWeight: "bold",
            color: "text.primary",
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ mb: 8, color: "text.secondary" }}>
          {description}
        </Typography>
        <Link
          href="#"
          sx={{
            fontSize: "base",
            fontWeight: "medium",
            color: "text.primary",
            "&:hover": { color: "primary.main" },
          }}
        >
          Learn More
        </Link>
      </Box>
    </Grid>
  );
}

export default FeatureItem;
