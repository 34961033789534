import React from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import PricingCard from "./PricingCard"; // Assuming PricingCard is in the same directory

function PricingSection() {
  return (
    <Box
      id="pricing"
      sx={{
        position: "relative",
        overflow: "hidden",
        bgcolor: "grey.100", // Use theme color
        pb: { xs: 12, lg: "90px" },
        pt: { xs: 20, lg: "120px" },
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ textAlign: "center", mb: "60px" }}>
          <Typography
            variant="subtitle1"
            sx={{ mb: 2, color: "primary.main", fontWeight: "bold" }}
          >
            Pricing Table
          </Typography>
          <Typography variant="h2" sx={{ mb: 3, color: "text.primary" }}>
            Awesome Pricing Plan
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Designed to cater to both aspiring job seekers and established
            professionals.
          </Typography>
        </Box>

        <Grid container spacing={4} justifyContent="center">
          <PricingCard
            title="Cover Letter Generator"
            price="4.99"
            features={[
              "Instant Cover Letter Creation",
              "Customizable Templates",
              "Industry-Specific Phrasing",
              "Downloadable PDF & DOC Format",
            ]}
            recommended
          />
          <PricingCard
            recommended
            title="LinkedIn Profile Review (Soon ..)"
            price="20.00"
            features={[
              "Comprehensive Profile Analysis",
              "Personalized Improvement Tips",
              "Professional Branding Advice",
              "Downloadable PDF & DOC Format",
            ]}
          />
        </Grid>
      </Container>
    </Box>
  );
}

export default PricingSection;
