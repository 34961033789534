import * as Yup from "yup";

export const coverValidate = Yup.object({
  firstName: Yup.string()
    .trim()
    .min(2, "First Name must be at least 2 characters")
    .max(30, "First Name must be at most 30 characters")
    .required("First Name is required"),

  lastName: Yup.string()
    .trim()
    .min(2, "Last Name must be at least 2 characters")
    .max(30, "Last Name must be at most 30 characters")
    .required("Last Name is required"),

  country: Yup.string()
    .trim()
    .max(50, "Country must be at most 50 characters")
    .required("Country is required"),

  address: Yup.string()
    .trim()
    .max(200, "Address must be at most 200 characters")
    .required("Address is required"),

  company: Yup.string()
    .trim()
    .max(100, "Company must be at most 100 characters")
    .required("Company is required"),

  yearsOfExperience: Yup.number()
    .positive("Years of Experience must be a positive number")
    .integer("Years of Experience must be an integer")
    .min(0, "Years of Experience must be at least 0")
    .max(50, "Years of Experience must be at most 50")
    .required("Years of Experience is required"),

  position: Yup.string()
    .trim()
    .max(100, "Position must be at most 100 characters")
    .required("Position is required"),

  jobDescription: Yup.string()
    .trim()
    .min(10, "Job Description must be at least 10 characters")
    .max(1000, "Job Description must be at most 1000 characters")
    .required("Job Description is required"),

  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),

  phoneNumber: Yup.string()
    .matches(
      /^(\+|00|0)(?:[0-9] ?){6,14}[0-9]$/,
      "Invalid phone number format (e.g., +1234567890, 001234567890, or 0123456789)"
    )
    .required("Phone Number is required"),
  additionalDetails: Yup.string()
    .trim()
    .max(400, "Additional Details must be at most 400 characters"),
});

export const cvReviewValidate = Yup.object({
  cv: Yup.string().required("CV is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});

export const linkedInReviewValidate = Yup.object({
  linkedinUrl: Yup.string()
    .required("LinkedIn URL is required")
    .matches(
      /^(https?:\/\/(www\.)?)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/,
      "Invalid LinkedIn URL format"
    ),
  agreed: Yup.boolean()
    .oneOf(
      [true],
      "You must agree to provide your LinkedIn profile information"
    )
    .required("Agreement is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});
