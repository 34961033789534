import React, { useContext } from "react";
import { Typography, Grid, Container, Box } from "@mui/material";
import { UserContext } from "../../context/Usercontext";
import { ReactComponent as Review } from "../../assets/review.svg";

const StepThreeCover = () => {
  const { userData } = useContext(UserContext);
  const displayKeys = [
    "firstName",
    "lastName",
    "country",
    "company",
    "yearsOfExperience",
    "position",
    "jobDescription",
    "email",
  ];

  return (
    <Container style={{ position: "relative" }}>
      <Box style={{ padding: "20px", marginTop: "20px" }}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
              Review Your Details
            </Typography>
            <Grid container spacing={2}>
              {Object.entries(userData)
                .filter(([key]) => displayKeys.includes(key))
                .map(([key, value]) => (
                  <React.Fragment key={key}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase())}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">{value}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          </Grid>
          <Grid
            style={{ display: "flex" }}
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            md={4}
          >
            <Review style={{ minHeight: "250px" }} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default StepThreeCover;
