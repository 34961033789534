import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";

function AboutImage({ src, alt, small }) {
  return (
    <Grid item xs={12} sm={6}>
      <Box
        sx={{
          mb: { xs: 4, sm: small ? 4 : 8 },
          height: {
            sm: small ? "220px" : "400px",
            md: small ? "346px" : "540px",
            lg: small ? "225px" : "400px",
            xl: small ? "310px" : "500px",
          },
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Box>
    </Grid>
  );
}

export default AboutImage;
