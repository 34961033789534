import React from "react";
import ServiceChoice from "../Steps/ServiceChoice/ServiceChoice";
import FillDetails from "../Steps/FillDetails/FillDetails";
import ReviewDetails from "../Steps/ReviewDetails/ReviewDetails";
import {
  coverValidate,
  cvReviewValidate,
  linkedInReviewValidate,
} from "../components/StepperContainer/validation";
import { coverInitValue } from "../components/StepperContainer/initValue";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import EditIcon from "@material-ui/icons/Edit";
import { pdfjs } from "react-pdf";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import axios from "axios";
import { Reviews } from "@mui/icons-material";
import PreviewResult from "../Steps/PreviewResult/PreviewResult";
import { services } from "./Constants";

// Example submit functions for each step
const nextStep = (values, actions, updateContext) => {
  // Replace with your actual logic for Step One
  updateContext({ ...values });
  actions.nextStep();
};

const fetchLinkedInData = async (values, actions, updateContext) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/services/linkedin/profile`,
    { ...values }
  );
  try {
    updateContext({
      linkedInProfileData: response?.data?.data?.linkedInProfileData,
      email: values.email,
      linkedInProfileId: response?.data?.data?.id,
    });
    console.log(response?.data?.data);
    if (response?.data?.data?.linkedInProfileData?.public_identifier) {
      actions.nextStep();
    } else {
      throw new Error("No Profile Found");
    }
  } catch (error) {
    console.error(error);
  }
};
//  CV Display
// const onSubmitStepTwo = (values, actions, updateContext) => {
//   try {
//     const fileReader = new FileReader();
//     fileReader.onload = async (e) => {
//       const typedArray = new Uint8Array(e.target.result);
//       const pdf = await pdfjs.getDocument({ data: typedArray }).promise;
//       let text = "";
//       for (let i = 1; i <= pdf.numPages; i++) {
//         const page = await pdf.getPage(i);
//         const textContent = await page.getTextContent();
//         text += textContent.items.map((item) => item.str).join(" ");
//       }
//       updateContext({ ...values, resumeText: text });
//       actions.nextStep();
//     };
//     fileReader.readAsArrayBuffer(values?.cv);
//   } catch (error) {
//     console.error("Error reading PDF:", error);
//   }
// };

// Extracted API call logic
const saveCoverLetter = async (values, actions, updateContext) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/services/cover-letter/generate`,
      { ...values }
    );
    const coverLetterId = response?.data?.data?.coverLetterId;
    const orderId = response?.data?.data?.orderId;
    if (coverLetterId && orderId) {
      updateContext({ coverLetterId, orderId });
      actions.nextStep();
    }
  } catch (error) {
    alert(
      "Oops! It looks like we're experiencing unusually high traffic at the moment. We're working hard to resolve this as quickly as possible. Please try again in a little while, and thank you for your patience!"
    );
    console.error("Error:", error);
    // Replace alert with more user-friendly error handling
  }
};
const resumeBasicReview = async (values, actions, updateContext) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/services/resume/basic-review`,
      { resumeText: values?.resumeText, email: values?.email }
    );
    const resumeId = response?.data?.data?.resumeId;
    const orderId = response?.data?.data?.orderId;
    if (resumeId && orderId) {
      updateContext({ resumeId, orderId });
      actions.nextStep();
    }
  } catch (error) {
    console.error("Error:", error);
    // Replace alert with more user-friendly error handling
  }
};

const linkedInReview = async (values, actions, updateContext) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/services/linkedin/review`,
      { linkedInProfileId: values?.linkedInProfileId, email: values?.email }
    );
    const orderId = response?.data?.data?.orderId;
    if (orderId) {
      updateContext({ orderId });
      actions.nextStep();
    }
  } catch (error) {
    console.error("Error:", error);
    // Replace alert with more user-friendly error handling
  }
};

// Add similar functions for other steps as needed

const stepsConfig = (contextValues) => {
  const fillDetailsValidationSchema = () => {
    switch (contextValues?.type) {
      case services?.coverLetter:
        return coverValidate;
      case services?.resumeLevelUp:
        return cvReviewValidate;
      case services?.linkedInReview:
        return linkedInReviewValidate;
      default:
        return null;
    }
  };

  return [
    {
      label: "How I can help you?",
      icon: <AssignmentTurnedInIcon />,
      validation: null,
      initValue: {},
      component: ServiceChoice,
      onSubmit: nextStep,
    },
    {
      label: "Fill Details",
      icon: <EditIcon />,
      validation: fillDetailsValidationSchema(),
      initValue: coverInitValue,
      component: FillDetails,
      onSubmit: (values, actions, updateContext) => {
        contextValues?.type == services?.linkedInReview
          ? fetchLinkedInData(values, actions, updateContext)
          : nextStep(values, actions, updateContext);
      },
    },
    {
      label: "Review and Make sure its correct",
      icon: <Reviews />,
      validation: null,
      initValue: {},
      component: ReviewDetails,
      onSubmit: (values, actions, updateContext) => {
        switch (contextValues?.type) {
          case services?.coverLetter:
            saveCoverLetter(values, actions, updateContext);
            break;
          case services?.linkedInReview:
            linkedInReview(contextValues, actions, updateContext);
            break;
          case services?.resumeLevelUp:
            resumeBasicReview(contextValues, actions, updateContext);
            break;
          default:
            break;
        }
      },
    },
    {
      label: "Get your Amazing Result!",
      icon: <DoneAllIcon />,
      validation: null,
      initValue: {},
      component: PreviewResult,
      onSubmit: (values, actions, updateContext) => {
        // Replace with your actual logic for Step Four
        updateContext({ ...values });
        actions.nextStep();
      },
    },
  ];
};

export default stepsConfig;
