import React from "react";
import { Box, Grid, Typography, Link, SvgIcon } from "@mui/material";
import { ReactComponent as Shape3 } from "../assets/images/footer/shape-3.svg";
import { ReactComponent as Decoration } from "../assets/decorationForSection.svg";
import logo from "../assets/Logo/white-logo.svg";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LaptopIcon from "@mui/icons-material/Laptop";
import FavoriteIcon from "@mui/icons-material/Favorite";
// Add SVG icon components here
// Example: const MySvgIcon = () => (<svg>...</svg>);

const Footer = () => {
  const SvgDecorations = () => {
    return (
      <Box>
        <Box sx={{ position: "absolute", bottom: 0, right: 0, zIndex: -1 }}>
          <Shape3 />
        </Box>
        <Box sx={{ position: "absolute", right: 0, top: 0, zIndex: -1 }}>
          <Decoration />
        </Box>
      </Box>
    );
  };
  const BottomBar = () => {
    return (
      <Box
        sx={{
          mt: 5,
          py: 5,
          borderTop: "1px solid #8890A4",
          color: "white",
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          {/* Footer Links */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <FooterLink href="/privacy-and-policy" title="Privacy policy" />

            <FooterLink href="/terms-and-conditions" title="Terms of service" />
          </Grid>

          {/* Credits */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: { xs: "center", md: "right" },
              mt: { xs: 2, md: 0 },
            }}
          >
            <Typography style={{ display: "inline-flex" }}>
              From Our Screens to Yours, With Care
              <FavoriteIcon
                style={{ margin: 2, marginLeft: 10 }}
                color="error"
              />
              <LaptopIcon />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const FooterLink = ({ href, title }) => (
    <Link
      href={href}
      sx={{
        px: 3,
        color: "white",
        textDecoration: "none",
        "&:hover": { color: "white" },
      }}
    >
      {title}
    </Link>
  );
  const FooterSection = ({ title, links }) => {
    return (
      <Grid item xs={12} sm={6} md={2}>
        <Box sx={{ px: 4 }}>
          <Typography
            variant="h6"
            sx={{ mb: 3, color: "white", fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <ul style={{ padding: 0 }}>
            {links.map((link, index) => (
              <SectionLink key={index} href={link.href} title={link.title} />
            ))}
          </ul>
        </Box>
      </Grid>
    );
  };
  const LogoSection = ({ Logo, description, socialLinks }) => {
    return (
      <Grid item xs={12} md={3}>
        <Box sx={{ px: 4, mb: 10 }}>
          <Box
            key={"footer"}
            sx={{ display: "inline-block", mb: 3, maxWidth: "160px" }}
          >
            <img src={Logo} width="150px" />
          </Box>
          <Typography sx={{ mb: 8, maxWidth: "270px", color: "white" }}>
            {description}
          </Typography>
          <Typography sx={{ mb: 2, maxWidth: "270px", color: "white" }}>
            support@careeruphold.com
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mx: -1 }}>
            {socialLinks.map((link, index) => (
              <SocialMediaLink key={index} href={link.url} icon={link.icon} />
            ))}
          </Box>
        </Box>
      </Grid>
    );
  };
  const SectionLink = ({ href, title }) => (
    <li>
      <Link
        href={href}
        sx={{
          display: "inline-block",
          mb: 2,
          color: "white",
          textDecoration: "none",
          "&:hover": { color: "primary.main" },
        }}
      >
        {title}
      </Link>
    </li>
  );

  const SocialMediaLink = ({ href, icon }) => (
    <Link
      href={href}
      sx={{ px: 1, color: "white", "&:hover": { color: "white" } }}
    >
      {icon}
    </Link>
  );
  const socialLinks = [
    { name: "Twitter", url: "https://twitter.com", icon: <TwitterIcon /> },
    { name: "Facebook", url: "https://facebook.com", icon: <FacebookIcon /> },
    {
      name: "Instagram",
      url: "https://instagram.com",
      icon: <InstagramIcon />,
    },
    { name: "LinkedIn", url: "https://linkedin.com", icon: <LinkedInIcon /> },
  ];

  const aboutLinks = [
    { title: "Home", href: "#" },
    { title: "Features", href: "#" },
    { title: "About", href: "#" },
  ];
  const howItWorkLinks = [
    { title: "Privacy policy", href: "/privacy-and-policy" },
    { title: "Terms of Service", href: "terms-and-conditions" },
  ];
  const ourProudctsLinks = [
    { title: "Cover Letter Generator", href: "/wizard" },
    { title: "LinkedIn Profile Review (Soon)", href: "/wizard" },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 10,
        bgcolor: "#090E34",
        px: 10,
        pb: 0,
        pt: "70px",
      }}
    >
      <Box className="container">
        <Grid container>
          <LogoSection
            Logo={logo}
            description="We design cutting-edge digital experiences for job seekers, utilizing advanced technology to connect you with your ideal career opportunities."
            socialLinks={socialLinks}
          />
          <FooterSection title="About Us" links={aboutLinks} />
          <FooterSection title="How It Works" links={howItWorkLinks} />
          <FooterSection title="Our Proudcts" links={ourProudctsLinks} />
          <FooterSection
            title="Latest Blog"
            links={[{ title: "Soon ....", href: "#" }]}
          />
        </Grid>
      </Box>

      <BottomBar />
      <SvgDecorations />
    </Box>
  );
};

export default Footer;
