import React, { useContext } from "react";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ColorlibConnector } from "./stepperStyles";
import { ColorlibStepIcon } from "./icons/icons";
import { UserContext } from "../../context/Usercontext";
import stepsConfig from "../../helpers/stepsConfig";

export default function CustomizedSteppers() {
  const { activeStep, userData } = useContext(UserContext);
  const currentStepsConfig = stepsConfig(userData);
  return (
    <Stack>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {currentStepsConfig.map(({ label }) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
