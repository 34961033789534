import React, { useContext, useEffect } from "react";
import CustomizedSteppers from "../CustomizedSteppers/CustomizedSteppers";
import {
  ContainerBox,
  StepperBox,
  ContentBox,
  ButtonBox,
  StyledButton,
} from "./stepperContainerStyles";
import { UserContext } from "../../context/Usercontext";
import { useFormik } from "formik";
import stepsConfig from "../../helpers/stepsConfig";
import { Box } from "@mui/material";
import ReactGA from "react-ga";
import initializeReactGA from "../../utils/initializeReactGA";
// Update the path as necessary

const StepperContainer = () => {
  const { userData, activeStep, updateUserData, handleNext, handleBack } =
    useContext(UserContext);

  const currentStepsConfig = stepsConfig(userData);

  // Initialize Google Analytics on component mount
  useEffect(() => {
    initializeReactGA();
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures this runs only once when the component mounts

  const formik = useFormik({
    initialValues: currentStepsConfig[activeStep]?.initValue,
    validationSchema: currentStepsConfig[activeStep]?.validation,
    onSubmit: (values) => {
      const currentStepConfig = currentStepsConfig[activeStep];
      if (currentStepConfig.onSubmit) {
        currentStepConfig.onSubmit(
          values,
          { nextStep: handleNext },
          updateUserData
        );
      }
      // Track form submission event
      ReactGA.event({
        category: "Form",
        action: "Submit",
        label: "Step Form Submission",
      });
    },
  });

  const StepComponent = currentStepsConfig[activeStep].component;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
        px: 3,
      }}
    >
      <ContainerBox>
        <StepperBox>
          <CustomizedSteppers activeStep={activeStep} />
        </StepperBox>
        <ContentBox
          style={{
            paddingTop: "50px",
            width: "100%",
            minHeight: "400px",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StepComponent formik={formik} />
        </ContentBox>
        <ButtonBox>
          <StyledButton
            color="secondary"
            style={{
              backgroundColor: "rgba(204,204,204)",
            }}
            disabled={activeStep === 0}
            onClick={() => {
              handleBack();
              ReactGA.event({
                // Track the back button click
                category: "Navigation",
                action: "Back Button",
                label: "Navigate Back",
              });
            }}
          >
            Back
          </StyledButton>
          <StyledButton
            color="secondary"
            variant="contained"
            onClick={() => {
              formik.handleSubmit();
              ReactGA.event({
                // Track the next/finish button click
                category: "Navigation",
                action: "Next/Finish Button",
                label:
                  activeStep === stepsConfig().length - 1 ? "Finish" : "Next",
              });
            }}
            disabled={activeStep === stepsConfig().length - 1}
          >
            {activeStep === stepsConfig().length - 1 ? "Finish" : "Next"}
          </StyledButton>
        </ButtonBox>
      </ContainerBox>
    </Box>
  );
};

export default StepperContainer;
