import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";

const NotFound = () => {
  return (
    <Container
      maxWidth="sm"
      style={{ textAlign: "center", marginTop: "100px" }}
    >
      <Typography variant="h2" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" gutterBottom>
        Oops! Page not found.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We can't seem to find the page you're looking for.
      </Typography>
      <Box mt={4}>
        <Button variant="contained" color="secondary" href="/">
          Go Home
        </Button>
      </Box>
    </Container>
  );
};
export default NotFound;
