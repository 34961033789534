import React from "react";
import { Container, Typography, Paper, Box, Link } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container>
      <Paper style={{ padding: "40px", margin: "40px" }}>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions of Use for Career Uphold
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Acceptance of Terms
        </Typography>
        <Typography paragraph>
          By accessing and using the Career Uphold application, you agree to be
          bound by these Terms and Conditions. If you do not agree with any part
          of these terms, you must not use the app.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Use of the Service
        </Typography>
        <Typography paragraph>
          Career Uphold provides a platform for generating cover letters,
          reviewing LinkedIn profiles, and offering personalized career
          development services. You agree to use these services responsibly and
          only for lawful purposes.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          User Responsibilities
        </Typography>
        <Box pl={2}>
          <Typography paragraph>
            <b>Data Provision:</b> You are responsible for any data you provide
            and must ensure it is accurate and up-to-date.
          </Typography>
          <Typography paragraph>
            <b>Lawful Use:</b> You agree not to use the app for any unlawful
            purpose or in a way that violates the rights of others.
          </Typography>
        </Box>

        <Typography variant="subtitle1" gutterBottom>
          Intellectual Property
        </Typography>
        <Typography paragraph>
          All content and features of the Career Uphold app, including but not
          limited to its design, text, graphics, and interfaces, are the
          property of Career Uphold and are protected by intellectual property
          laws.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography paragraph>
          Your use of Career Uphold is also governed by our Privacy Policy,
          which outlines how we collect, use, and protect your personal
          information.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          User Data and Consent
        </Typography>
        <Typography paragraph>
          By using the app, you consent to the collection and use of your
          personal data as outlined in our Privacy Policy.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Third-Party Links
        </Typography>
        <Typography paragraph>
          Career Uphold may contain links to third-party websites or services.
          We are not responsible for the privacy practices or content of these
          external sites.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Disclaimer of Warranties
        </Typography>
        <Typography paragraph>
          The app is provided “as is” without any warranties of any kind. Career
          Uphold does not guarantee the accuracy, completeness, or usefulness of
          any information on the app.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Limitation of Liability
        </Typography>
        <Typography paragraph>
          Career Uphold shall not be liable for any damages resulting from the
          use or inability to use the app, including but not limited to direct,
          indirect, incidental, punitive, and consequential damages.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Amendments to Terms
        </Typography>
        <Typography paragraph>
          We reserve the right to modify these terms at any time. Your continued
          use of the app following any changes means you accept the new terms.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Termination
        </Typography>
        <Typography paragraph>
          We may terminate or suspend your access to the app immediately,
          without prior notice, for any breach of these Terms.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Governing Law
        </Typography>
        <Typography paragraph>
          These Terms shall be governed by the laws of the jurisdiction where
          Career Uphold operates, without regard to its conflict of law
          provisions.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Contact Us
        </Typography>
        <Typography paragraph>
          For any questions about these Terms, please contact us .
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Acknowledgment
        </Typography>
        <Typography paragraph>
          By using Career Uphold, you acknowledge that you have read,
          understood, and agree to be bound by these Terms and Conditions.
        </Typography>
      </Paper>
    </Container>
  );
};

export default TermsAndConditions;
