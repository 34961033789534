import React from "react";
import { Container, Typography, Box, Paper } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Paper style={{ padding: "40px", margin: "40px" }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Box my={2}>
          <Typography variant="subtitle1">1. Introduction</Typography>
          <Typography paragraph>
            This Privacy Policy provides details on how "Career Uphold"
            collects, uses, and safeguards your personal information. By
            utilizing our app, you agree to the practices described in this
            policy.
          </Typography>

          <Typography variant="subtitle1">2. Data Collection</Typography>
          <Typography paragraph>
            "Career Uphold" collects personal information necessary to
            facilitate the generation of cover letters and review of LinkedIn
            profiles. The type of data collected will depend on the services you
            use and the information you choose to provide.
          </Typography>

          <Typography variant="subtitle1">
            3. Purpose of Data Processing
          </Typography>
          <Typography paragraph>
            The purpose of collecting data is to assist in creating and
            reviewing resumes and cover letters, and to provide personalized
            services related to career development.
          </Typography>

          <Typography variant="subtitle1">
            4. Consent and User Rights
          </Typography>
          <Typography paragraph>
            Consent: Your personal information is provided voluntarily for the
            purpose of using our services. User Rights: You have the right to
            access, amend, or delete your personal data. To exercise these
            rights, please contact us using the information provided below.
          </Typography>

          <Typography variant="subtitle1">
            5. Data Sharing and Third Parties
          </Typography>
          <Typography paragraph>
            We do not share your data with third parties. Your personal
            information is exclusively used within "Career Uphold" for the
            services provided.
          </Typography>

          <Typography variant="subtitle1">6. Data Security Measures</Typography>
          <Typography paragraph>
            We implement advanced security measures to protect your personal
            information from unauthorized access, alteration, or destruction.
          </Typography>

          <Typography variant="subtitle1">7. Data Retention Period</Typography>
          <Typography paragraph>
            Your personal data is retained only as long as necessary for the
            purposes stated in this Privacy Policy, unless a longer retention is
            required or permitted by law.
          </Typography>

          <Typography variant="subtitle1">
            8. Policy Updates and User Notification
          </Typography>
          <Typography paragraph>
            We may update this policy as necessary. Users will be informed of
            any significant changes through the app or other communication
            methods.
          </Typography>

          <Typography variant="subtitle1">9. Contact Information</Typography>
          <Typography paragraph>
            If you have any questions or concerns about this Privacy Policy or
            our data handling practices, please contact us .
          </Typography>

          <Typography variant="subtitle1">
            10. Acknowledgment and Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By using "Career Uphold," you acknowledge and agree to the terms set
            forth in this Privacy Policy.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
