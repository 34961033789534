import React from "react";
import { Box, Container, Typography, Button, Grid } from "@mui/material";
import About1 from "../../../assets/images/about/about-image-01.webp";
import About2 from "../../../assets/images/about/about-image-02.webp";
import AboutImage from "./AboutImage";

function AboutSection() {
  return (
    <Box
      id="about"
      sx={{
        bgcolor: "grey.100",
        pb: { xs: 8, lg: 7 },
        pt: { xs: 20, lg: 15 },
      }}
    >
      <Container>
        <Box className="wow fadeInUp" data-wow-delay=".2s">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} lg={6}>
              <Box sx={{ mb: { xs: 12, lg: 0 }, maxWidth: 540 }}>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 5,
                    fontSize: { sm: "40px" },
                    fontWeight: "bold",
                    color: "text.primary",
                  }}
                >
                  Revolutionize Your Job Applications with Career UPHOLD
                </Typography>
                <Typography
                  sx={{ mb: 10, fontSize: "base", color: "text.secondary" }}
                >
                  " Career uphold " App is designed to empower job seekers with
                  advanced tools for creating, reviewing, and optimizing resumes
                  and cover letters. Our app uses innovative technology and
                  expert insights to help users present their best selves to
                  potential employers
                  <br />
                  <br />
                  The app's intuitive interface and personalized feedback
                  mechanisms ensure that your job applications stand out in the
                  competitive job market.
                </Typography>
                <Button
                  href="#"
                  variant="contained"
                  sx={{
                    borderColor: "primary.main",
                    bgcolor: "primary.main",
                    color: "white",
                    "&:hover": {
                      bgcolor: "primary.dark",
                      borderColor: "primary.dark",
                    },
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container spacing={{ xs: 2, sm: 4, lg: 2 }}>
                <AboutImage src={About1} alt="about image" />
                <AboutImage src={About2} alt="about image" />

                {/* Additional AboutImage components for other images */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default AboutSection;
