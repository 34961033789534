import React, { useState } from "react";
import { Box, Button, Typography, CircularProgress } from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const pdfWrapperRef = React.useRef();
  const [width, setWidth] = React.useState();

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setWidth(entry.target.clientWidth * 0.5);
      }
    });
    resizeObserver.observe(pdfWrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      ref={pdfWrapperRef}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {file ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<CircularProgress />}
          >
            <Page
              width={width || undefined}
              pageNumber={pageNumber}
              renderTextLayer={false}
            />
          </Document>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              maxWidth: "100%",
              overflow: "scroll",
            }}
          >
            <Button onClick={previousPage} disabled={pageNumber <= 1}>
              <ArrowBackIosIcon color="secondary" />
            </Button>
            <Box>
              <Typography>
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </Typography>
            </Box>
            <Button onClick={nextPage} disabled={pageNumber >= numPages}>
              <ArrowForwardIosIcon color="secondary" />
            </Button>
          </Box>
        </Box>
      ) : (
        <Typography>No PDF file selected</Typography>
      )}
    </Box>
  );
};

export default PDFViewer;
