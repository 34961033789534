import { styled } from "@mui/material/styles";
import { Box, Card, CardContent } from "@mui/material";
import theme from "../../theme/theme";

export const OptionsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

export const OptionCard = styled(Card)({
  width: "100%",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0),
  [theme.breakpoints.down("sm")]: {
    minWidth: "100%",
  },
});

export const OptionCardContent = styled(CardContent)({
  textAlign: "center",
});

export const IconStyle = (theme) => ({
  fontSize: 60,
  color: theme.palette.secondary.main, // Fancy primary color
});
