import React, { createContext, useState, useCallback, useEffect } from "react";

import axios from "axios";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Temp Loader Handler
  // Setting up Axios interceptors
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use((config) => {
      setIsLoading(true); // Show loader
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setIsLoading(false); // Hide loader
        return response;
      },
      (error) => {
        setIsLoading(false); // Hide loader on error
        return Promise.reject(error);
      }
    );

    return () => {
      // Eject interceptors when component unmounts
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  const updateUserData = (newData) => {
    setUserData({ ...userData, ...newData });
  };

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  return (
    <UserContext.Provider
      value={{
        userData,
        updateUserData,
        activeStep,
        handleNext,
        handleBack,
        isLoading,
        setIsLoading,
        services,
        setServices,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
