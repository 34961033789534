import React from "react";
import { Button, Typography, Grid, Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import { color3 } from "../../theme/theme";
import { ReactComponent as Upload } from "../../assets/upload.svg";
import CustomTextField from "../../components/common/CustomTextField/CustomTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "auto",
  },
  uploadButton: {
    margin: theme.spacing(2),
  },
  fileDetails: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  motivationalText: {
    marginTop: theme.spacing(2),
    fontStyle: "italic",
    color: color3,
    textAlign: "center",
  },
  dropzone: {
    border: `2px dashed ${color3}`,
    borderRadius: "5px",
    padding: theme.spacing(4),
    textAlign: "center",
    cursor: "pointer",
    margin: theme.spacing(2),
  },
  succesUpload: {
    padding: theme.spacing(4),
    textAlign: "center",
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },

  successIcon: {
    color: "green",
    fontSize: "60px",
    marginRight: theme.spacing(1),
  },
}));

const FileUploadForm = ({ formik }) => {
  const classes = useStyles();

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      formik.setFieldValue("cv", acceptedFiles[0]);
    },
    [formik]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxSize: 5242880, // 5MB
  });

  const handleDelete = () => {
    formik.setFieldValue("cv", null);
  };

  return (
    <Box justifyContent="center" className={classes.root}>
      <Typography
        variant="h4"
        className={classes.motivationalText}
        textAlign="center"
      >
        Embark on a New Chapter in Your Career! 🚀
      </Typography>
      <Typography className={classes.motivationalText} textAlign="center">
        Upload Your CV Now and Discover the Secrets to a Standout Resume. Let's
        refine, enhance, and transform your professional story together. Your
        dream job awaits!
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        {formik.values.cv ? (
          <Grid item>
            <Box className={classes.dropzone}>
              <Upload style={{ height: "300px" }} />
              <Typography>
                {formik.values.cv.name} -{" "}
                {(formik.values.cv.size / 1024 / 1024).toFixed(2)} MB
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.deleteButton}
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Typography>
            </Box>
          </Grid>
        ) : (
          <Grid item>
            <Box {...getRootProps()} className={classes.dropzone}>
              <input {...getInputProps()} />
              <Upload style={{ height: "300px" }} />
              <Typography>
                Drag 'n' drop your CV here, or click here to select a file
              </Typography>
            </Box>
            {formik.errors.cv && (
              <Typography style={{ textAlign: "center" }} color="error">
                {formik.errors.cv}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        justifyContent="center"
        container
        style={{ marginTop: "20px" }}
        item
      >
        <Grid md={6} xs={12} item>
          <CustomTextField name="email" label="Email" formik={formik} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FileUploadForm;
