import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import EmailIcon from "@mui/icons-material/Email";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import styles from "./Success.module.css";
import { ReactComponent as Completed } from "../../assets/completed.svg";
import { Grid, Box } from "@mui/material";

const Success = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [order, setOrder] = useState(null);
  const [error, setError] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("order");

  useEffect(() => {
    if (orderId) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/order/status/${orderId}`)
        .then((response) => {
          if (response.data?.status) {
            setOrder(true);
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  }, []);

  const handleBackToServices = () => {
    navigate("/"); // Replace '/services' with the actual path to your service list
  };

  return (
    <div className={styles.successPage}>
      {!error && (
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Paper className={styles.paper}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Completed style={{ maxHeight: "200px" }} />
                <Typography
                  variant="h4"
                  gutterBottom
                  className={styles.message}
                >
                  Congratulations on Your New Beginning!
                </Typography>
                <Typography variant="body1" className={styles.message}>
                  We're thrilled to be a part of your journey. Your Materials is
                  crafted and ready. Look out for an email from us!
                </Typography>
                <Typography variant="body1" className={styles.message}>
                  Please ensure you have received your materials by checking
                  both your inbox and spam folder. Be aware that it might take
                  about 10 minutes for the materials to arrive in your email. If
                  you encounter any issues or have questions, our support team
                  is here to help. Reach out to us support@careeruphold.com for
                  prompt assistance.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 20 }}
                  onClick={handleBackToServices}
                >
                  Back to Our Service List
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}

      {(error || !order) && (
        <Alert severity="error">
          <WarningAmberIcon style={{ marginRight: 10 }} />
          There was an issue retrieving your cover letter. Please check your
          payment status and email.
        </Alert>
      )}
    </div>
  );
};

export default Success;
