import React, { useContext } from "react";
import CustomTextField from "../../components/common/CustomTextField/CustomTextField";
import { Grid, Typography } from "@mui/material";
import { UserContext } from "../../context/Usercontext";

export default function StepTwoCover({ formik }) {
  const { userData } = useContext(UserContext);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Cover Letter Step</Typography>
      </Grid>

      {/* First Name */}
      <Grid item xs={12} sm={6}>
        <CustomTextField
          label="First Name"
          variant="outlined"
          name="firstName"
          formik={formik}
          fullWidth
        />
      </Grid>

      {/* Last Name */}
      <Grid item xs={12} sm={6}>
        <CustomTextField
          label="Last Name"
          name="lastName"
          formik={formik}
          variant="outlined"
          fullWidth
        />
      </Grid>

      {/* Last Name */}
      <Grid item xs={12} sm={12}>
        <CustomTextField
          label="Your Address"
          name="address"
          formik={formik}
          variant="outlined"
          fullWidth
        />
      </Grid>

      {/* Country */}
      <Grid item xs={12} sm={6}>
        <CustomTextField
          value={userData?.Country}
          label="Country"
          name="country"
          formik={formik}
          variant="outlined"
          fullWidth
        />
      </Grid>

      {/* Company You Want to Apply For */}
      <Grid item xs={12} sm={6}>
        <CustomTextField
          label="Company You Want to Apply For"
          variant="outlined"
          fullWidth
          formik={formik}
          name="company"
        />
      </Grid>

      {/* Years of Experience */}
      <Grid item xs={12} sm={6}>
        <CustomTextField
          name="yearsOfExperience"
          formik={formik}
          label="Years of Experience"
          variant="outlined"
          fullWidth
          type="number"
        />
      </Grid>

      {/* Position */}
      <Grid item xs={12} sm={6}>
        <CustomTextField
          value={userData?.position}
          name="position"
          formik={formik}
          label="Position"
          variant="outlined"
          fullWidth
        />
      </Grid>
      {/* Job Description */}
      <Grid item xs={12}>
        <CustomTextField
          name="jobDescription"
          formik={formik}
          label="Job Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
      {/* Email */}
      <Grid item xs={12} sm={6}>
        <CustomTextField
          label="Email"
          name="email"
          formik={formik}
          variant="outlined"
          fullWidth
          type="email"
        />
      </Grid>
      {/* Phone Number */}
      <Grid item xs={12} sm={6}>
        <CustomTextField
          value={userData?.phoneNumber}
          label="Phone Number"
          name="phoneNumber"
          formik={formik}
          variant="outlined"
          fullWidth
          type="tel"
        />
      </Grid>
      {/* Text Area for Additional Details */}
      <Grid item xs={12}>
        <CustomTextField
          value={userData?.additionalDetails}
          label="Additional Details"
          name="additionalDetails"
          variant="outlined"
          formik={formik}
          fullWidth
          rows={4}
        />
      </Grid>
    </Grid>
  );
}
