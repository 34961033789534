import React from "react";
import { Box, Container, Typography, Grid, Link } from "@mui/material";
import FeatureItem from "./FeatureItem";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TuneIcon from "@mui/icons-material/Tune";

function FeaturesSection() {
  return (
    <Box
      sx={{
        pb: { xs: 8, lg: 9 },
        pt: { xs: 20, lg: 15 },
        bgcolor: "background.default",
      }}
    >
      <Container>
        <Box
          sx={{
            textAlign: "center",
            mb: { xs: 12, lg: 9 },
            maxWidth: 485,
            mx: "auto",
          }}
        >
          <Typography
            sx={{
              mb: 2,
              fontSize: "lg",
              fontWeight: "bold",
              color: "primary.main",
            }}
          >
            Features
          </Typography>
          <Typography
            sx={{
              mb: 3,
              fontSize: { sm: "3xl", md: "40px" },
              fontWeight: "bold",
              color: "text.primary",
            }}
          >
            Discover the Features
          </Typography>
          <Typography sx={{ fontSize: "base", color: "text.secondary" }}>
            Boost your job applications with our intelligent resume and cover
            letter review tool.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {/* Feature items here */}
          <FeatureItem
            delay=".1s"
            title="Expert Resume Analysis"
            description="Get personalized feedback and recommendations to enhance your resume."
            icon={<AssignmentIndIcon />}
          />
          <FeatureItem
            title="Effective Cover Letter Crafting"
            description="Create compelling cover letters with professional guidance and proven templates."
            icon={<EditIcon />}
          />
          <FeatureItem
            title="Comprehensive Skill Assessment"
            description="Identify and showcase your key skills effectively in your job applications."
            icon={<AssessmentIcon />}
          />
          <FeatureItem
            title="Job-Focused Customization"
            description="Tailor your application documents to align perfectly with your target job roles."
            icon={<TuneIcon />}
          />
        </Grid>
      </Container>
    </Box>
  );
}

export default FeaturesSection;
