import React from "react";
import Hero from "./Hero/Hero";
import Features from "./Features/Features";
import About from "./About/About";
import CTA from "./CTA/CTA";
import Pricing from "./Pricing/Pricing";
import FAQ from "./FAQ/FAQ";
import Contact from "./Contact/Contact";

export default function Home() {
  return (
    <div>
      <Hero />
      <Features />
      <About />
      <CTA />
      <FAQ />
      <Pricing />
      {/* <Contact /> */}
    </div>
  );
}
