import React, { useContext, useEffect } from "react";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import Header from "./components/Header";
import Footer from "./components/Footer";
import StepperContainer from "./components/StepperContainer/StepperContainer";
import theme from "./theme/theme";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { UserContext } from "./context/Usercontext";
import "./App.css";
import Success from "./components/Success/Success";
import NotFound from "./components/NotFound/NotFound";
import Loader from "./components/Loader/Loader";
import Home from "./components/Home/Home";
import PrivacyPolicy from "./components/Pages/PrivacyAndPolicy";
import TermsAndConditions from "./components/Pages/TermsAndCondition";
import ReactGA from "react-ga";
const App = () => {
  const { isLoading } = useContext(UserContext);

  ReactGA.initialize("G-LL160FLHQ7");

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: "#eef2f5",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Example shadow
          borderRadius: "4px", // Example border radius
        }}
      >
        <Router>
          <Header />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
            }}
          >
            {isLoading && <Loader />}

            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Home />} />
              <Route path="/wizard" element={<StepperContainer />} />
              <Route path="/success" element={<Success />} />
              <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              {/* <Route path="/cancel" component={Cancel} /> */}
              {/* Add other routes as needed */}
            </Routes>
          </Box>
        </Router>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default App;
