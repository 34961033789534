import { createTheme } from "@mui/material/styles";

// Your provided colors
export const color1 = "rgb(55 88 249)"; // Can be used for primary
export const color2 = "rgb(11,180,137)"; // Can be used for secondary
export const color3 = "rgb(138,35,135)";

const theme = createTheme({
  typography: { fontFamily: ["Mulish", "cursive"].join(",") },
  palette: {
    primary: {
      main: color1,
      contrastText: "#ffffff", // White text for better readability on primary color
    },
    secondary: {
      main: color2,
      contrastText: "#ffffff", // White text for better readability on secondary color
    },

    // You can add more colors to the palette if needed
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Custom styles for buttons
          fontWeight: "bold",
          color: "white",
          textTransform: "none",
          fontSize: "15px",
          padding: "10px 30px",
        },
        containedPrimary: {
          // Specific styles for primary contained buttons
          "&:hover": {
            filter: "brightness(90%)",
          },
        },
        containedSecondary: {
          // Specific styles for secondary contained buttons
          "&:hover": {
            filter: "brightness(90%)",
          },
        },
      },
    },
    // Overrides for other components can be added here
  },
  // Additional customizations for the theme
});

export default theme;
