import React, { useContext, useEffect, useState } from "react";
import { Typography, CardActionArea, Grid } from "@mui/material";
import { UserContext } from "../../context/Usercontext";
import { OptionsContainer } from "./style";
import { ReactComponent as Experts } from "../../assets/experts.svg";
import theme from "../../theme/theme";
import axios from "axios";
import ServiceCard from "./ServiceCard/ServiceCard";

function ServiceChoice() {
  const { updateUserData, handleNext, services, setServices } =
    useContext(UserContext);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/services`
        ); // Adjust API endpoint as needed

        if (response?.data.status === 1) {
          setServices(response?.data?.data);
        } else {
          console.error("Failed to fetch services:", response?.data.message);
        }
      } catch (error) {
        console.error("Failed to fetch services:", error);
      }
    };
    services.length === 0 && fetchServices();
  }, []);

  const handleOptionSelect = (selectedOption) => {
    updateUserData({ type: selectedOption });
    handleNext();
  };

  return (
    <OptionsContainer theme={theme}>
      <Grid container>
        <Grid
          style={{ display: "flex" }}
          justifyContent={"center"}
          alignItems={"center"}
          xs={12}
        >
          {/* <Experts style={{ maxHeight: "330px" }} /> */}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              margin: "10px",
              textAlign: "center",
              marginBottom: "30px",
            }}
            variant="h5"
          >
            Navigate Your Future with Confidence: How Can Our Services Guide
            You?
          </Typography>
        </Grid>

        <Grid
          xs={12}
          container
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {services?.map(
            (service) =>
              service?.availability && (
                <Grid
                  key={service._id}
                  item
                  xs={12}
                  md={5}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <ServiceCard
                    service={service}
                    onSelect={handleOptionSelect}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Grid>
    </OptionsContainer>
  );
}

export default ServiceChoice;
