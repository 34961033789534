import React, { useContext } from "react";
import Check from "@mui/icons-material/Check";
import { color1, color2, color3 } from "../../../theme/theme";
import stepsConfig from "../../../helpers/stepsConfig";
import { UserContext } from "../../../context/Usercontext";

export const QontoStepIcon = ({ active, completed }) => (
  <div
    style={{
      color: active ? "#784af4" : "#eaeaf0",
      display: "flex",
      height: 22,
      alignItems: "center",
    }}
  >
    {completed ? (
      <Check style={{ color: "#784af4", zIndex: 1, fontSize: 18 }} />
    ) : (
      <div
        style={{
          width: 8,
          height: 8,
          borderRadius: "50%",
          backgroundColor: "currentColor",
        }}
      />
    )}
  </div>
);

export const ColorlibStepIcon = ({ active, completed, icon }) => {
  const { userData } = useContext(UserContext);
  const currentstepsConfig = stepsConfig(userData);
  return (
    <div
      style={{
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: active ? "0 4px 10px 0 rgba(0,0,0,.25)" : "none",
        backgroundImage:
          active || completed
            ? `linear-gradient(95deg, ${color1} 0%, ${color1} 50%, ${color1} 100%)`
            : "none",
      }}
    >
      {currentstepsConfig[icon - 1]?.icon}
    </div>
  );
};
