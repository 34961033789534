import React from "react";
import { Box, CardActionArea, Grid, Typography } from "@mui/material";
import { OptionCard, OptionCardContent } from "../style";
import { ReactComponent as LinkedInReview } from "../../../assets/icons/linkedInReview.svg";
import { ReactComponent as CoverLetter } from "../../../assets/icons/coverLetter.svg";
import { services } from "../../../helpers/Constants";

const ServiceCard = ({ service, onSelect }) => {
  const renderIcon = () => {
    switch (service.name) {
      case services.linkedInReview:
        return <LinkedInReview height="100px" />;
      case services.coverLetter:
        return <CoverLetter height="100px" />;
      default:
        return <></>;
        break;
    }
  };

  return (
    <OptionCard
      style={{
        height: "220px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardActionArea
        style={{ height: "100%" }}
        onClick={() => onSelect(service?.name)}
      >
        <OptionCardContent
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid item></Grid>
          <Typography variant="h5" component="div">
            {service.displayName}
          </Typography>
          <Box padding="10px">{renderIcon()}</Box>
          <Typography variant="body2" color="text.secondary">
            {service.description}
          </Typography>
        </OptionCardContent>
      </CardActionArea>
    </OptionCard>
  );
};

export default ServiceCard;
