import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const messages = [
  "We appreciate your patience, something fantastic is coming your way!",
  "Creating something unique for you, just a bit longer...",
  "Great things take time, your exceptional experience is on the way!",
  "Almost ready! Preparing an extraordinary experience just for you...",
];

const Loader = () => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000); // Change message every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{
        position: "fixed", // Fixed position
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent background
        zIndex: 1000, // High z-index to overlay on top of other content
      }}
    >
      <CircularProgress />
      <Typography
        color="primary"
        variant="h5"
        style={{ marginTop: 20, padding: "20px", textAlign: "center" }}
      >
        {messages[messageIndex]}
      </Typography>
    </Box>
  );
};

export default Loader;
