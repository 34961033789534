import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/Usercontext";
import ReviewDetailsCover from "./ReviewDetailsCover";
import ReviewDetailsResume from "./ReviewDetailsResume";
import { services } from "../../helpers/Constants";
import ReviewDetailsLinkedIn from "./ReviewDetailsLinkedIn";

export default function ReviewDetails({ formik }) {
  const { userData } = useContext(UserContext);

  return (
    <>
      <div>
        {userData?.type === services?.coverLetter && (
          <ReviewDetailsCover formik={formik} />
        )}
      </div>
      <div>
        {userData?.type === services?.resumeLevelUp && <ReviewDetailsResume />}
      </div>
      <div>
        {userData?.type === services?.linkedInReview && (
          <ReviewDetailsLinkedIn />
        )}
      </div>
    </>
  );
}
