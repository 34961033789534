import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function FAQItem({ question, answer, icon }) {
  return (
    <Card elevation={0} sx={{ display: "flex", alignItems: "center", mb: 3 }}>
      <Box sx={{ bgcolor: "primary.main", borderRadius: "20%", p: 1.5, m: 2 }}>
        {icon}
        {/* <CheckCircleOutlineIcon sx={{ fontSize: 32, color: "white" }} /> */}
      </Box>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {question}
        </Typography>
        <Typography variant="body1">{answer}</Typography>
      </CardContent>
    </Card>
  );
}

export default FAQItem;
