import React, { useContext } from "react";
import { UserContext } from "../../context/Usercontext";
import FillDetailsResume from "./FillDetailsResume";
import FillDetailsCover from "./FillDetailsCover";
import { services } from "../../helpers/Constants";
import FillDetailsLinkedIn from "./FillDetailsLinkedIn";

export default function FillDetails({ formik }) {
  const { userData } = useContext(UserContext);

  return (
    <>
      {userData?.type === services?.coverLetter && (
        <FillDetailsCover formik={formik} />
      )}

      {userData?.type === services?.resumeLevelUp && (
        <FillDetailsResume formik={formik} />
      )}
      {userData?.type === services?.linkedInReview && (
        <FillDetailsLinkedIn formik={formik} />
      )}
    </>
  );
}
