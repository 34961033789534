export const coverInitValue = {
  initialValues: {
    firstName: "",
    lastName: "",
    country: "",
    company: "",
    yearsOfExperience: "",
    position: "",
    jobDescription: "",
    email: "",
    address: "",
    phoneNumber: "",
    additionalDetails: "",
  },
};
