import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { ReactComponent as CTA1 } from "../../../assets/images/CTA/cta-1.svg";
import { ReactComponent as CTA2 } from "../../../assets/images/CTA/cta-2.svg";
function GetStartedSection() {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        bgcolor: "primary.main",
        py: { xs: 20, lg: "70px" },
        textAlign: "center",
        color: "white",
      }}
    >
      <Container>
        <Box>
          <Box sx={{ maxWidth: 570, mx: "auto", mb: 3 }}>
            <Typography
              variant="h3"
              sx={{ mb: 2.5, fontSize: { md: "38px" }, fontWeight: "bold" }}
            >
              <span>What Are You Looking For?</span>
              <span sx={{ fontSize: { md: "40px" }, fontWeight: "normal" }}>
                {" "}
                Ready to Elevate Your Career? Begin Today
              </span>
            </Typography>
            <Typography sx={{ mx: "auto", maxWidth: 515, fontSize: "base" }}>
              Explore a wealth of opportunities and resources tailored for your
              professional growth. Forget the generic content – here, every
              piece of advice and tool is crafted to help you succeed in your
              unique career journey.
            </Typography>
          </Box>
          <Button
            href="#"
            variant="contained"
            sx={{
              borderRadius: 1,
              bgcolor: "secondary.main",
              px: 7,
              py: 2,
              "&:hover": { bgcolor: "#0BB489" },
            }}
          >
            Start using our app
          </Button>
        </Box>
      </Container>

      {/* Placeholder for SVGs */}
      <Box sx={{ position: "absolute", left: 0, top: 0 }}>
        <CTA1 />
      </Box>
      <Box sx={{ position: "absolute", bottom: 0, right: 0 }}>
        <CTA2 />
      </Box>
    </Box>
  );
}

export default GetStartedSection;
