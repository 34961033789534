import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";

export const ContainerBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  height: "100%",
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 3,
  paddingRight: 3,
});

export const StepperBox = styled(Box)({});

export const ContentBox = styled(Box)({
  flex: 1, // Take up remaining space
});

export const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

export const StyledButton = styled(Button)({
  margin: "10px",
});
