import React, { useState, useContext, useEffect } from "react";
import { Button, Typography, Box, CircularProgress } from "@material-ui/core";
import { pdfjs, Document, Page } from "react-pdf";
import { UserContext } from "../../context/Usercontext";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PDFViewer from "../../components/PDFViewer/PDFViewer";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ReviewDetailsResume = () => {
  const { userData } = useContext(UserContext);

  return (
    <>
      <Typography style={{ marginBottom: "20px" }} variant="h5" color="Primary">
        {`Your Email is : ${userData?.email}`}
      </Typography>
      <Typography
        style={{ marginBottom: "20px" }}
        variant="h5"
        color="secondary"
      >
        📝 Give your CV a final check to ensure it's perfectly tailored for your
        upcoming career upgrade. Ready to take it to new heights? 🚀
      </Typography>
      <div>{userData.cv && <PDFViewer file={userData?.cv} />}</div>
    </>
  );
};

export default ReviewDetailsResume;
